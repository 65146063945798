// TODO: Remove, unused for Pfister

// import $ from 'jquery'
import i18n from '../i18n.js'

class GuestOfferDrink {
  onLoad() {
    this.$el = $('[data-info]')

    this.info = $('[data-info]').data('info')
    console.log(this.info)

    this.updatePollyAndTranslations(this.info)

    this.relatedExternalId = this.info.related_si_external_id
    this.$el.find("[data-info-tag='your-code']").text(this.translate('coffee__your_code'))
    this.$el.find("[data-info-tag='please-come']").html(this.seeYouText())
    //this.playSpeech('si_location', this.info.locale)
  }

  updatePollyAndTranslations(info) {
    const polly = info.polly
    const translations = info.terminal_strings
    let pollyMap = {}
    let translationMap = {}

    const addToMap = (map, p) => {
      if (!map[p.language]) {
        map[p.language] = {}
      }
      map[p.language][p.translation_id] = p
    }

    _.each(_.filter(polly, p => p.shop_id == null && p.infoterminal_id == null), p => addToMap(pollyMap, p))
    _.each(_.filter(translations, p => p.shop_id == null && p.infoterminal_id == null), p => addToMap(translationMap, p))

    if (info.shop && info.shop.id) {
      _.each(_.filter(polly, p => p.shop_id == info.shop.id), p => addToMap(pollyMap, p))
      _.each(_.filter(translations, p => p.shop_id == info.shop.id), p => addToMap(translationMap, p))
    }

    if (info.infoterminal && info.infoterminal.id) {
      _.each(_.filter(polly, p => p.infoterminal_id == info.infoterminal.id), p => addToMap(pollyMap, p))
      _.each(_.filter(translations, p => p.infoterminal_id == info.infoterminal.id), p => addToMap(translationMap, p))
    }

    this.speech = pollyMap
    this.translationMap = translationMap
  }

  seeYouText() {
    if (this.relatedExternalId) {
      const t = this.translate('map__see_you_on_si_floor_' + this.relatedExternalId)
      if (t.length) {
        return t
      }
    }
    return this.translate('map__see_you_on_si_floor')
  }

  translate(key, interpolations = {}) {
    const lang = this.info.locale
    if (!(lang in this.translationMap)) {
      return ''
    }

    const lng = this.translationMap[lang]

    if (!(key in lng)) {
      return ''
    }
    let val = lng[key].html
    for (let k in interpolations) {
      val = val.replace(`%{${k}}`, interpolations[k])
    }
    return val
  }

  playSpeech(key, lang, replace=null, siSpecific=false) {
    if (this.speech && this.speech[lang]) {
      if (siSpecific) {
        if (this.speech[lang][key + '_' + relatedExternalId]) {
          key = key + '_' + relatedExternalId
        }
      }

      const sp = this.speech[lang][key]
      var ssml =  sp.ssml
      if (replace) {
        for (var k in replace) {
          ssml = ssml.replace(`{${k}}`, replace[k])
        }
      }

      if (!ssml.startsWith('<speak>')) {
        ssml = `<speak>${ssml}</speak>`
      }

      this.loadSynthesizedSpeech(ssml, lang, sp.voice_id)
    }
  }

  playSound(sndBody) {
    try {
      const snd = new Audio("data:audio/mpeg;base64," + sndBody)

      if (this.currentSound) {
        this.currentSound.volume = 0;
      }

      this.currentSound = snd
      snd.play()
    } catch(err) {
      console.error(err)
    }
  }

  loadSynthesizedSpeech(ssml, language, voice) {
    const params = {
      text: ssml,
      language: language,
      voice_id: voice
    }

    console.log(`Synthesizing using Polly: ${language}, ${voice}, ${ssml})`)

    $.ajax({
      url: this.getLambdaUrl() + `conversations/synthesize`,
      type : 'POST',
      data: params,
      success: data => {
        this.playSound(data['_body'])
      },
    })

  }
}
export default GuestOfferDrink
